import { Message, Spin, Input, Form, Button } from "@arco-design/web-react";
import { useState } from "react";
import { cryptPwd } from "../../utils/crypto";
import { axios, logout } from "../../utils/net";
const FormItem = Form.Item;
function ChangePassword() {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();

    const onOk = (res) => {
        let val = {
            password: cryptPwd(res.password),
            new_password: cryptPwd(res.new_password),
            re_new_password: cryptPwd(res.re_new_password)
        }
        setConfirmLoading(true);
        axios.post('/public/change_password', val).then((res) => {
          if (res.data.code !== 0) {
            Message.warning(res.data.msg);
          } else {
            Message.success('密码修改成功 !');
            form.clearFields(['password', 'new_password', 're_new_password']);
            // 重新请求用户数据
            logout();
          }
        }).catch(() => {
          Message.error('发生未知错误 !');
          // setConfirmLoading(false);
        }).finally(() => {
          setConfirmLoading(false);
        });
    }

    return (
        <>
            <Spin loading={confirmLoading}>
                <div style={{ width: 500, margin: '0 auto', marginTop: '50px' }}>
                    <Form
                        form={form}
                        style={{ width: 500 }}
                        // wrapperCol={{ span: 24 }}
                        autoComplete='off'
                        // onValuesChange={(v, vs) => {
                        //     // console.log(v, vs);
                        // }}
                        onSubmit={(v) => {
                            // console.log(v);
                            onOk(v)
                        }}
                    >
                        <FormItem field='password' rules={[{ required: true, message: '请输入当前密码' }]}>
                            <Input.Password placeholder='输入当前密码' />
                        </FormItem>
                        <FormItem
                            field='new_password'
                            dependencies={['password']}
                            rules={[{
                                // required: true,
                                // message: '请输入新密码',
                                validator: (v, cb) => {
                                    if (!v) {
                                        return cb('新密码不能为空')
                                    } else if (form.getFieldValue('password') === v) {
                                        return cb('新密码不能和旧密码一致')
                                    }
                                    if (v.length < 6) {
                                        return cb('密码长度最少为6位。')
                                    }
                                    var ls = 0;
                                    if (v.match(/([a-z])+/)) {
                                        ls++;
                                    }
                                    if (v.match(/([0-9])+/)) {
                                        ls++;
                                    }
                                    if (v.match(/([A-Z])+/)) {
                                        ls++;
                                    }
                                    if (v.match(/[^a-zA-Z0-9]+/)) {
                                        ls++;
                                    }
                                    if (ls < 3) {
                                        return cb('密码强度不够，请包含数字、字母、特殊字符中的至少三种')
                                    }
                                    cb(null)
                                }
                            }]}>
                            <Input.Password placeholder='要使用的新密码' />
                        </FormItem>
                        <FormItem
                            field='re_new_password'
                            dependencies={['new_password']}
                            placeholder='请再次输入新密码'
                            rules={[{
                                validator: (v, cb) => {
                                    if (!v) {
                                        return cb('确认密码不能为空')
                                    } else if (form.getFieldValue('new_password') !== v) {
                                        return cb('确认密码和新密码不一致')
                                    }
                                    cb(null)
                                }
                            }]}
                        >
                            <Input.Password placeholder='请再次输入新密码' />
                        </FormItem>
                        <FormItem>
                            <Button type='primary' long htmlType="submit">
                                修改密码
                            </Button>
                        </FormItem>
                    </Form>
                </div>
            </Spin>
        </>
    );
}

export default ChangePassword;