// import { useRef } from 'react';
import { Input } from '@arco-design/web-react';
import { Table, Button, Switch, Form, Message, Modal } from '@arco-design/web-react';
import { IconCheck, IconClose } from '@arco-design/web-react/icon';
import { useEffect, useState } from 'react';
import { axios } from '../../utils/net';
const FormItem = Form.Item;
// import { IconSearch } from '@arco-design/web-react/icon';
// const data = [
//     {
//         id: '1',
//         username: '管理员员工',
//         identity: 1,
//         status: 1,
//     },
// ];
function ManagerUser() {
    const [initFlag, setInitFlag] = useState(false);
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();
    const [dataLoading, setDataLoading] = useState(true);
    const [pagination, setPagination] = useState({
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
    });
    const [data, setData] = useState([]);
    const [actionId, setActionId] = useState(0);

    const loadData = ({ offset, limit }) => {
        // console.log(offset,limit);
        setDataLoading(true);
        axios.post("/manager/getusers", {
            offset: offset === undefined ? pagination.current - 1 : offset,
            limit: limit === undefined ? pagination.pageSize : limit,
        }).then((res) => {
            if (res.data.code === 0) {
                setPagination({
                    ...pagination,
                    current: res.data.data.offset + 1,
                    total: res.data.data.count,
                });
                setData(res.data.data.payload === null ? [] : res.data.data.payload);
            } else {
                Message.error(res.data.msg);
            }
        }).catch((err) => {
            console.log(err);
            Message.error("获取数据失败");
        }).finally(() => {
            setDataLoading(false);
        });
    }

    const setUserStatus = ({ id, status }) => {
        if (actionId !== 0) {
            Message.error("请先等待操作完成！");
            return;
        }
        setActionId(id);
        // console.log("setUserStatus", id, status);
        axios.post("/manager/set_user_status", {
            id: id,
            status: status === undefined ? -1 : status,
        }).then((res) => {
            if (res.data.code === 0) {
                // 修改data中指定用户的状态
                setData(data.map((item) => {
                    if (item.ID === id) {
                        item.Status = status;
                    }
                    return item;
                }));
            } else {
                Message.error(res.data.msg);
            }
        }).catch((err) => {
            console.log(err);
            Message.error("获取数据失败");
        }).finally(() => {
            setActionId(0);
        });
    }

    // const loadDataCallBack = useCallback(() => {
    //     loadData({
    //         offset: 0,
    //         limit: 10,
    //     });
    // }, [loadData]);

    useEffect(() => {
        // console.log(initFlag);
        if (initFlag) {
            return
        }
        axios.post("/manager/getusers", {
            offset: 0,
            limit: 10,
        }).then((res) => {
            if (res.data.code === 0) {
                setPagination({
                    ...pagination,
                    current: res.data.data.offset + 1,
                    total: res.data.data.count,
                });
                setData(res.data.data.payload === null ? [] : res.data.data.payload);
            } else {
                Message.error(res.data.msg);
            }
        }).catch((err) => {
            console.log(err);
            Message.error("获取数据失败");
        }).finally(() => {
            setDataLoading(false);
            setInitFlag(true);
        });
    });


    function onOk() {
        form.validate().then((res) => {
            // console.log(res);
            setConfirmLoading(true);
            axios.post('/manager/adduser', res).then((res) => {
                if (res.data.code !== 0) {
                    Message.warning(res.data.msg);
                } else {
                    Message.success('添加管理员成功 !');
                    setVisible(false);
                    form.clearFields(['username', 'password', 'repassword']);
                    loadData(pagination.current - 1, pagination.pageSize)
                    // 重新请求用户数据
                }
            }).catch(() => {
                Message.error('发生未知错误 !');
                // setConfirmLoading(false);
            }).finally(() => {
                setConfirmLoading(false);
            });
        }).catch((err) => {
            console.log(err);
            Message.warning('表单必填项目不能为空 !');
        });
    }

    const formItemLayout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 20,
        },
    };
    //   const inputRef = useRef(null);
    const columns = [
        {
            title: '用户ID',
            dataIndex: 'ID',
            //   filterIcon: <IconSearch />,
            //   filterDropdown: ({ filterKeys, setFilterKeys, confirm }) => {
            //     return (
            //       <div className='arco-table-custom-filter'>
            //         <Input.Search
            //           ref={inputRef}
            //           searchButton
            //           placeholder='Please enter name'
            //           value={filterKeys[0] || ''}
            //           onChange={(value) => {
            //             setFilterKeys(value ? [value] : []);
            //           }}
            //           onSearch={() => {
            //             confirm();
            //           }}
            //         />
            //       </div>
            //     );
            //   },
            //   onFilter: (value, row) => (value ? row.name.indexOf(value) !== -1 : true),
            //   onFilterDropdownVisibleChange: (visible) => {
            //     if (visible) {
            //       setTimeout(() => inputRef.current.focus(), 150);
            //     }
            //   },
        },
        {
            title: '用户账号',
            dataIndex: 'Username',
        },
        {
            title: '用户身份',
            dataIndex: 'Username',
            render: (_, record) => (
                record.Identity === 0 ? '公司账户' : record.Identity === 1 ? '管理员' : record.Identity === 2 ? '管理员工' : '未知'
            )
        },
        {
            title: '用户状态',
            dataIndex: 'Status',
            render: (status, record) => (
                <>
                    <Switch loading={actionId === record.ID} checkedIcon={<IconCheck />} uncheckedIcon={<IconClose />} checked={status === 1} onChange={(val, event) => { setUserStatus({ id: record.ID, status: status === 1 ? -1 : 1 }) }} />
                </>
            )
        },
        // {
        //     title: '操作',
        //     //   dataIndex: 'email',
        //     dataIndex: 'op',
        //     render: (_, record) => (
        //         <Button
        //             onClick={() => {
        //                 console.log(record);
        //             }}
        //             type='primary'
        //             status='danger'
        //         >
        //             Delete
        //         </Button>
        //     )
        // },
    ];

    function onChangeTable(paginationData) {
        // console.log(paginationData);
        const { current, pageSize } = paginationData;
        setPagination({
            ...pagination,
            current: current,
            pageSize: pageSize
        });
        // console.log(current,pageSize);
        loadData({ offset: current - 1, limit: pageSize });
        // setPagination({ ...pagination, current, pageSize });
        // loadData();
    }

    return (
        <>
            <Button
                style={{
                    marginBottom: 10,
                    marginTop: 10,
                    marginLeft: 10,
                    width: '200px'
                }}
                type='primary'
                onClick={() => { setVisible(true) }}
            >
                添加管理员
            </Button>
            <Table
                loading={dataLoading}
                columns={columns}
                data={data}
                onChange={onChangeTable}
                pagination={pagination}
            />
            <Modal
                title='添加系统员工账户'
                visible={visible}
                onOk={onOk}
                confirmLoading={confirmLoading}
                onCancel={() => setVisible(false)}
            >

                <Form
                    {...formItemLayout}
                    form={form}
                    initialValues={{
                        identity: 2,
                        status: 1,
                    }}
                    labelCol={{
                        style: { flexBasis: 90 },
                    }}
                    wrapperCol={{
                        style: { flexBasis: 'calc(100% - 90px)' },
                    }}
                >
                    <FormItem label='账号' field='username' rules={[{ required: true, message: '请输入账号' }]}>
                        <Input placeholder='登录所需账号' />
                    </FormItem>
                    <FormItem label='密码' field='password' rules={[{ required: true, message: '请输入密码' }]}>
                        <Input.Password placeholder='登录所需密码' />
                    </FormItem>
                    <FormItem label='确认密码' field='repassword' rules={[{
                        required: true, validator: (v, cb) => {
                            if (!v) {
                                return cb('请再次输入密码')
                            } else if (form.getFieldValue('password') !== v) {
                                return cb('两次输入的密码不一致')
                            }
                            cb(null)
                        }
                    }]}>
                        <Input.Password placeholder='与登陆密码一致' />
                    </FormItem>
                    <FormItem label='状态' field='status' hidden={true} >
                        <Input placeholder='状态' />
                    </FormItem>
                    <FormItem label='身份' field='identity' hidden={true} >
                        <Input placeholder='身份' />
                    </FormItem>
                </Form>
            </Modal>
        </>
    );
}


export default ManagerUser;