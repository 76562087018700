import React from 'react';
import { Layout, Menu, Breadcrumb, Button} from '@arco-design/web-react';
import { IconCalendar, IconImport } from '@arco-design/web-react/icon';
import '../../style/layout.css';
import { checkPath, filterMenuByRole } from '../../utils/menu';
import { Link, Route, Routes } from 'react-router-dom';
import { getUser, logout } from '../../utils/net';
const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;
const Sider = Layout.Sider;
const Header = Layout.Header;
const Footer = Layout.Footer;
const Content = Layout.Content;

let selectKey = ''

// const [visible, setVisible] = useState(false);
// // const [fileList,setFileList] = useState([]);
// const [confirmLoading, setConfirmLoading] = useState(false);
// const [form] = Form.useForm();

class MenuLayout extends React.Component {
  state = {
    routerFirst: true,
    collapsed: false,
  };
  handleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  constructor(props) {
    super(props);
    const user = getUser();
    let menu = filterMenuByRole(user.identify);
    this.state = {
      menu: menu,
    };
    selectKey = checkPath(window.location.pathname)
    // 遍历menu

  }
  // 处理一级菜单栏
  renderMenu = ({ title, key }) => {
    // console.log(key);
    return (
      <Link to={key}>
        <MenuItem key={key}>
          <IconCalendar />
          {title}
        </MenuItem>
      </Link>
    )
  }
  // 处理子级菜单栏
  renderSubMnenu = ({ title, key, submenu }) => {
    // console.log("---",submenu);
    // console.log(key);
    return (
      <SubMenu key={key} title={title}>
        {
          submenu && submenu.map(item => {
            // console.log(item.key);
            return submenu.submenu && item.submenu.length > 0 ? this.renderSubMnenu(item) : this.renderMenu(item)
          })
        }
      </SubMenu>
    )
  }

  self = this;
  // 处理一级路由
  renderRoute = ({ key, component }) => {
    // console.log(this.routerFirst);
    // if(window.location.pathname.endsWith(key)) {
    //   this.setState({
    //     selectKey: key
    //   })
    // }
    if (!this.routerFirst) {
      this.routerFirst = true;
      return (
        <>
          <Route path='/' element={component} />
          <Route path={key} element={component} />
        </>
      );
    }
    return (
      <Route path={key} element={component} />
    )
  }

  renderSubRoute = ({ submenu }) => {
    return (
      <>
        {
          submenu && submenu.map(item => {
            return submenu.submenu && item.submenu.length > 0 ? this.renderSubRoute(item) : this.renderRoute(item)
          })
        }
      </>
    )
  }

  render() {
    return (
      <>
        <Layout className='layout-collapse-demo'>
          <Sider collapsed={false} collapsible trigger={null} breakpoint='xl'>
            <div className='logo'>
              <center>
                <p>{this.state.collapsed ? "建安" : "山东建安合同管理系统"}</p>
              </center>
            </div>
            <Menu
              autoOpen={true}
              defaultSelectedKeys={[selectKey]}
              onClickMenuItem={(key) =>
                // console.log();
                // Message.info({
                //   content: `You select ${key}`,
                //   showIcon: true,
                // })
                <></>
              }
              style={{ width: '100%' }}
            >
              {this.state.menu && this.state.menu.map((item, _) => {
                return item.submenu && item.submenu.length > 0 ? this.renderSubMnenu(item) : this.renderMenu(item)
              })}
              <MenuItem
                key='logout'
                onClick={logout}
              >
                <IconImport />
                退出系统
              </MenuItem>
            </Menu>
          </Sider>
          <Layout>
            <Header style={{ display: 'flex', justifyContent: 'flex-end', justifyItems: 'center', alignItems: 'center' }}>
              {/* <Button shape='round' className='trigger' onClick={this.handleCollapsed}>
              {this.state.collapsed ? <IconCaretRight /> : <IconCaretLeft />}
            </Button> */}
              <Button type='text' style={{ margin: '0 10px' }} onClick={() => { this.setState({ visible: true }) }}>修改密码</Button>
            </Header>
            <Layout style={{ padding: '0 24px' }}>
              <Breadcrumb style={{ margin: '16px 0' }}>
                {/* <Breadcrumb.Item>Home</Breadcrumb.Item> */}
              </Breadcrumb>
              <Content>
                <Routes>
                  {this.state.menu && this.state.menu.map((item, _) => {
                    return item.submenu && item.submenu.length > 0 ? this.renderSubRoute(item) : this.renderRoute(item)
                  })}

                </Routes>
              </Content>
              <Footer>山东建安合同管理系统</Footer>
            </Layout>
          </Layout>
        </Layout>
      </>
    );
  }
}


export default MenuLayout;