// import { useRef } from 'react';
import { Table, Button, Watermark, Message, Tag, Modal, Space } from '@arco-design/web-react';
import { useEffect, useState } from 'react';
import { axios } from '../../utils/net';
import { GetFilesBlob, SaveBlob } from '../../utils/downloader';

function SendboxManage() {
    // const [visible, setVisible] = useState(false);
    // const [confirmLoading, setConfirmLoading] = useState(false);
    // const [form] = Form.useForm();

    const [initFlag, setInitFlag] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [pagination, setPagination] = useState({
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
    });
    const [data, setData] = useState([]);
    // const [infoLoading, setInfoLoading] = useState(false);


    const loadData = ({ offset, limit }) => {
        // console.log(offset,limit);
        setDataLoading(true);
        axios.post("/manager/outbox", {
            offset: offset === undefined ? pagination.current - 1 : offset,
            limit: limit === undefined ? pagination.pageSize : limit,
        }).then((res) => {
            if (res.data.code === 0) {
                setPagination({
                    ...pagination,
                    current: res.data.data.offset + 1,
                    total: res.data.data.count,
                });
                setData(res.data.data.payload === null ? [] : res.data.data.payload);
            } else {
                Message.error(res.data.msg);
            }
        }).catch((err) => {
            console.log(err);
            Message.error("获取数据失败");
        }).finally(() => {
            setDataLoading(false);
        });
    }

    useEffect(() => {
        // console.log(initFlag);
        if (initFlag) {
            return
        }
        axios.post("/manager/outbox", {
            offset: 0,
            limit: 10,
        }).then((res) => {
            if (res.data.code === 0) {
                setPagination({
                    ...pagination,
                    current: res.data.data.offset + 1,
                    total: res.data.data.count,
                });
                setData(res.data.data.payload === null ? [] : res.data.data.payload);
            } else {
                Message.error(res.data.msg);
            }
        }).catch((err) => {
            console.log(err);
            Message.error("获取数据失败");
        }).finally(() => {
            setDataLoading(false);
            setInitFlag(true);
        });
    });

    // function onOk() {
    //     setVisible(false);
    // }

    //   const inputRef = useRef(null);
    const columns = [
        {
            title: '发送标题',
            dataIndex: 'Title',
        },
        {
            title: '接收人',
            dataIndex: 'Username',
        },
        {
            title: '发送时间',
            dataIndex: 'Time',
        },
        {
            title: '附加消息',
            dataIndex: 'Msg',
        },
        {
            title: '状态',
            dataIndex: 'Status',
            render: (status, _) => (
                <>
                    {status === -1 ? <Tag color="orange">已发送</Tag> : status === 1 ? <Tag color="blue">已查收</Tag> : status === 2 ? <Tag color="green">已回执</Tag> : <Tag color="red">未知</Tag>}
                </>
            )
        },
        {
            title: '操作',
            //   dataIndex: 'email',
            dataIndex: 'op',
            render: (_, record) => (
                <Button
                    onClick={() => {
                        ShowInfo(record.ID)
                    }}
                    type='primary'
                    status='danger'
                    disabled={record.Status !== 2}
                >
                    查看回执
                </Button>
            )
        },
    ];

    const ShowInfo = (id) => {
        setDataLoading(true);
        axios.post("/manager/outbox/receipts", {
            id: id
        }).then((res) => {
            if (res.data.code === 0) {
                // console.log(res);
                Modal.info({
                    title: '合同回执详情',
                    okText: '确认',
                    content: InfoNode(res.data.data),
                });
            } else {
                Message.error(res.data.msg);
            }
        }).catch((err) => {
            console.log(err);
            Message.error("获取数据失败");
        }).finally(() => {
            setDataLoading(false);
        });
    }

    const getContractFiles = (files) => {
        setDataLoading(true);
        // console.log(res.data.data);
        Message.info("正在解析文件。。。");
        GetFilesBlob(files).then((res) => {
            // console.log(res);
            SaveBlob(res);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setDataLoading(false);
        });
    }


    const InfoNode = (data) => {
        let rtype = (data.File === '' || data.file === null) ? 0 : 1;
        return (
            <>
                <Space>
                    <>
                        回执方式：{rtype === 0 ? "快递邮寄" : "电子合同"}
                    </>
                    {
                        rtype === 1
                            ?
                            <Button
                                type="primary"
                                onClick={() => {
                                    getContractFiles(data.File.split(","))
                                }}
                            >
                                下载电子合同
                            </Button>
                            :
                            <>
                                快递单号：{data.Exnum}
                            </>
                    }
                </Space >
            </>
        )
    }

    function onChangeTable(paginationData) {
        // console.log(paginationData);
        const { current, pageSize } = paginationData;
        setPagination({
            ...pagination,
            current: current,
            pageSize: pageSize
        });
        // console.log(current,pageSize);
        loadData({ offset: current - 1, limit: pageSize });
        // setPagination({ ...pagination, current, pageSize });
        // loadData();
    }

    return <>
        <Watermark content={"山东建安合同管理系统"}>
            <Table
                loading={dataLoading}
                columns={columns}
                data={data}
                onChange={onChangeTable}
                pagination={pagination}
            />
            {/* <Modal
                title='合同回执详情'
                visible={visible}
                onOk={onOk}
                confirmLoading={confirmLoading}
                onCancel={() => setVisible(false)}
            >
                <Spin tip="合同加载..." loading={infoLoading}>

                </Spin>
            </Modal> */}
        </Watermark>
    </>;
}
export default SendboxManage;