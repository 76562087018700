// import JSZip from 'jszip'
// import { saveAs } from 'file-saver'
// import { axios } from './net'

import { baseURL } from "./net";

function GetFilesBlob(files) {
    let token = window.localStorage.getItem("token");
    return new Promise((resolve, reject) => {
        // axios.post('/public/download_file', {
        //     // path: fid,
        //     responseType: 'blob'
        // }).then(res => {
        //     resolve(res.data)
        // }).catch(err => {
        //     reject(err)
        // })
        let request = new XMLHttpRequest()
        request.open("POST", baseURL + '/public/download_file', true)
        request.responseType = "blob"
        request.setRequestHeader('Authorization', 'Bearer ' + token)
        request.onload = (res) => {
            if (res.target.status === 200) {
                resolve(res.target.response)
            } else {
                reject(res)
            }
        }
        request.send(JSON.stringify({
            path: files
        }))
    })
}

// const DownloadFiles = (files) => {
//     const zip = new JSZip()
//     let result = []
//     // let files = [
//     //     "https://im-1305783521.cos.ap-guangzhou.myqcloud.com/im/file/C13012345678/1645261335302/测试文档.doc",
//     //     "https://im-1305783521.cos.ap-guangzhou.myqcloud.com/im/file/lilinjian/1641631041478/dd.txt",
//     //     "https://im-1305783521.cos.ap-guangzhou.myqcloud.com/im/file/C13012345678/1650420333942/123.xls",
//     //     "https://im-1305783521.cos.ap-guangzhou.myqcloud.com/im/file/C13012345678/1641805597561/巴达兽.png"
//     // ]
//     for (let i in files) {
//         let promise = getFileBlob(files[i]).then((res) => {
//             let format = files[i].substring(files[i].lastIndexOf("."), files[i].length)
//             zip.file(i + format, res, { binary: true })
//         })
//         result.push(promise)
//     }
//     Promise.all(result).then(() => {
//         zip.generateAsync({ type: "blob" }).then((res) => {
//             saveAs(res, "合同附件.zip")
//         })
//     })
// }


const convertRes2Blob = (response) => {
    // 提取文件名
    // console.log(response);
    const fileName = response.headers['content-disposition'].match(
        /filename=(.*)/
    )[1]
    // 将二进制流转为blob
    const blob = new Blob([response.data], { type: 'application/octet-stream' })
    // console.log(blob);
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
        window.navigator.msSaveBlob(blob, decodeURI(fileName))
    } else {
        // 创建新的URL并指向File对象或者Blob对象的地址
        const blobURL = window.URL.createObjectURL(blob)
        // 创建a标签，用于跳转至下载链接
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', decodeURI(fileName))
        // 兼容：某些浏览器不支持HTML5的download属性
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
        }
        // 挂载a标签
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        // 释放blob URL地址
        window.URL.revokeObjectURL(blobURL)
    }
}


const SaveBlob = (blob) => {
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
        window.navigator.msSaveBlob(blob, "合同附件.zip")
    } else {
        // 创建新的URL并指向File对象或者Blob对象的地址
        const blobURL = window.URL.createObjectURL(blob)
        // 创建a标签，用于跳转至下载链接
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', "合同附件.zip")
        // 兼容：某些浏览器不支持HTML5的download属性
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
        }
        // 挂载a标签
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        // 释放blob URL地址
        window.URL.revokeObjectURL(blobURL)
    }
}

export {  convertRes2Blob ,GetFilesBlob, SaveBlob}