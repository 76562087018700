// import { useRef } from 'react';
import { Message, Table, Watermark, Tag, Button, Space, Form, Modal, Input, Upload, Radio } from '@arco-design/web-react';
import { useEffect, useState } from 'react';
import { axios, baseURL } from '../../utils/net';
import { GetFilesBlob, SaveBlob } from '../../utils/downloader';
// import { IconSearch } from '@arco-design/web-react/icon';
const FormItem = Form.Item;
function Inbox() {
  const token = window.localStorage.getItem("token");
  const [initFlag, setInitFlag] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [pagination, setPagination] = useState({
    sizeCanChange: false,
    showTotal: true,
    total: 0,
    pageSize: 10,
    current: 1,
    pageSizeChangeResetCurrent: true,
  });
  const [data, setData] = useState([]);
  // const [confirmLoading, setConfirmLoading] = useState(false);

  const [pushVisible, setPushVisible] = useState(false);
  const [pushConfirmLoading, setPushConfirmLoading] = useState(false);
  const [push_form] = Form.useForm();
  // 合同类型
  const [ctype, setCtype] = useState(0);

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 20,
    },
  };

  const loadData = ({ offset, limit }) => {
    // console.log(offset,limit);
    setDataLoading(true);
    axios.post("/user/inbox", {
      offset: offset === undefined ? pagination.current - 1 : offset,
      limit: limit === undefined ? pagination.pageSize : limit,
    }).then((res) => {
      if (res.data.code === 0) {
        setPagination({
          ...pagination,
          current: res.data.data.offset + 1,
          total: res.data.data.count,
        });
        setData(res.data.data.payload === null ? [] : res.data.data.payload);
      } else {
        Message.info('暂无合同接受');
      }
    }).catch((err) => {
      console.log(err);
      Message.error("获取数据失败");
    }).finally(() => {
      setDataLoading(false);
    });
  }

  const getContractFiles = (id) => {
    setDataLoading(true);
    axios.post("/user/inbox/files", {
      id: id,
    }).then((res) => {
      if (res.data.code === 0) {
        console.log(res);
        console.log(res.data.data);
        let files = [];
        for (let i = 0; i < res.data.data.length; i++) {
          files.push(res.data.data[i]);
        }
        Message.info("正在解析文件。。。");
        GetFilesBlob(files).then((res) => {
          console.log(res);
          SaveBlob(res);
        }).catch((err) => {
          console.log(err);
        }).finally(() => {
          setDataLoading(false);
        });
        // 文件id列表
        // axios.post("/public/download_file", {
        //   path: files,
        // }).then((res) => {
        //   convertRes2Blob(res);
        // }).catch((err) => {
        //   console.log(err);
        //   Message.error("文件下载失败");
        // });
        // for (let i = 0; i < res.data.data.length; i++) {
        //   axios.post("/public/download_file", {
        //     path: res.data.data[i],
        //   }).then((res) => {
        //     convertRes2Blob(res);
        //   }).catch((err) => {
        //     console.log(err);
        //     Message.error("第" + i + "个文件下载失败");
        //   });
        // }
        // let files = res.data.data;
        // setDataLoading(false);
      } else {
        Message.error(res.data.msg);
      }
    }).catch((err) => {
      console.log(err);
      Message.error("获取数据失败");
    });
  }

  useEffect(() => {
    // console.log(initFlag);
    if (initFlag) {
      return
    }
    axios.post("/user/inbox", {
      offset: 0,
      limit: 10,
    }).then((res) => {
      if (res.data.code === 0) {
        setPagination({
          ...pagination,
          current: res.data.data.offset + 1,
          total: res.data.data.count,
        });
        setData(res.data.data.payload === null ? [] : res.data.data.payload);
      } else {
        Message.info('暂无合同接收');
      }
    }).catch((err) => {
      console.log(err);
      Message.error("获取数据失败");
    }).finally(() => {
      setDataLoading(false);
      setInitFlag(true);
    });
  });
  //   const inputRef = useRef(null);
  const columns = [
    {
      title: 'ID',
      dataIndex: 'ID',
    },
    {
      title: '合同标题',
      dataIndex: 'Title',
    },
    {
      title: '合同说明',
      dataIndex: 'Msg',
    },
    {
      title: '发送时间',
      dataIndex: 'Time',
    },
    {
      title: '状态',
      dataIndex: 'Status',
      render: (status, _) => (
        <>
          {status === -1 ? <Tag color="orange">未查收</Tag> : status === 1 ? <Tag color="blue">已查收</Tag> : status === 2 ? <Tag color="green">已回执</Tag> : <Tag color="red">未知</Tag>}
        </>
      )
    },
    {
      title: '操作',
      //   dataIndex: 'email',
      dataIndex: 'op',
      render: (_, record) => (
        <Space size='large'>
          <Button
            onClick={() => {
              getContractFiles(record.ID)
            }}
            type='primary'
            status='default'
          >
            查看附件
          </Button>
          <Button
            onClick={() => {
              push_form.setFields({ id: { value: record.ID } })
              push_form.clearFields(['exnum', 'upload','upload2']);
              setPushVisible(true)
            }}
            type='primary'
            status='success'
            disabled={record.Status === 2}
          >
            提交回执
          </Button>
        </Space>
      )
    },
  ];

  function onChangeTable(paginationData) {
    // console.log(paginationData);
    const { current, pageSize } = paginationData;
    setPagination({
      ...pagination,
      current: current,
      pageSize: pageSize
    });
    // console.log(current,pageSize);
    loadData({ offset: current - 1, limit: pageSize });
    // setPagination({ ...pagination, current, pageSize });
    // loadData();
  }

  function pushOnOk() {
    push_form.validate().then((res) => {
      console.log(res);
      // 获取合同上传状态和targetLink
      var file = [];
      if (res.ctype === 0) {
        if (res.upload.length === 0) {
          Message.error("请上传合同");
          return;
        }
        if (res.upload2.length === 0) {
          Message.error("请上传发票");
          return;
        }
        // 检查所有附件的上传状态
        for (var j = 0; j < res.upload.length; j++) {
          if (res.upload[j].status !== 'done') {
            Message.error("请等待所有附件上传完成");
            return;
          }
        }
        for (var s = 0; s < res.upload2.length; s++) {
          if (res.upload2[s].status !== 'done') {
            Message.error("请等待所有附件上传完成");
            return;
          }
        }
        for (var i = 0; i < res.upload.length; i++) {
          file.push(res.upload[i].response.data.data.link);
        }
        for (var k = 0; k < res.upload2.length; k++) {
          file.push(res.upload2[k].response.data.data.link);
        }
      } else {
        if (push_form.getFieldValue('exnum') === '' || push_form.getFieldValue('exnum') === undefined) {
          Message.error("请填写快递单号");
        }
      }
      setPushConfirmLoading(true);
      console.log(file);
      let val = push_form.getFieldsValue(['id', 'exnum']);
      val = { ...val, file }
      axios.post('/user/receipts/change', val).then((res) => {
        if (res.data.code !== 0) {
          Message.warning(res.data.msg);
        } else {
          Message.success('回执合同成功 !');
          setPushVisible(false);
          loadData(pagination.current - 1, pagination.pageSize)
          // 重新请求用户数据
        }
      }).catch(() => {
        Message.error('发生未知错误 !');
        // setConfirmLoading(false);
      }).finally(() => {
        setPushConfirmLoading(false);
      });
      // setVisible(false);
      console.log(val);
    }).catch(err => {
      console.log(err);
      Message.error('参数错误 !');
    }).finally(() => {
      // setPushVisible(false);
      setPushConfirmLoading(false);
    });
  }
  push_form.setFields({
    ctype: {
      value: ctype,
    }
  })
  return <>
    <Watermark content={"山东建安合同管理系统"}>
      <Table
        loading={dataLoading}
        columns={columns}
        data={data}
        onChange={onChangeTable}
        pagination={pagination}
      />
      {/* <Modal
          title='合同发送详情'
          visible={visible}
          onOk={onOk}
          confirmLoading={confirmLoading}
          onCancel={() => setVisible(false)}
      >
          <Spin tip="合同加载..." loading={infoLoading}>

          </Spin>
      </Modal> */}
      <Modal
        title='提交回执'
        visible={pushVisible}
        onOk={pushOnOk}
        confirmLoading={pushConfirmLoading}
        onCancel={() => setPushVisible(false)}
      >
        <Form
          {...formItemLayout}
          form={push_form}
          labelCol={{
            style: { flexBasis: 90 },
          }}
          wrapperCol={{
            style: { flexBasis: 'calc(100% - 90px)' },
          }}
        >
          <FormItem label='合同ID' field='id' hidden disabled rules={[{ required: true }]}>
            <Input placeholder='' />
          </FormItem>
          <FormItem label='合同类型' field='ctype' rules={[{ required: true }]}>
            <Radio.Group type='button' defaultValue={ctype} onChange={setCtype}>
              <Radio value={0}>电子合同</Radio>
              <Radio value={1}>纸质合同（邮寄）</Radio>
            </Radio.Group>
          </FormItem>
          <Form.Item
            hidden={ctype !== 0}
            rules={[{ required: ctype === 0 }]}
            label='合同上传'
            field='upload'
            triggerPropName='fileList'
            help='请上传文件附件'
          >
            <Upload
              // listType='picture-card'
              // multiple
              // autoUpload={false}
              name='file'
              accept={'image/*,.pdf,.zip'}
              action={baseURL + '/public/upload_file'}
              headers={
                {
                  'Authorization': 'Bearer ' + token,
                  'FileType': ctype,
                }
              }
            />
          </Form.Item>
          <Form.Item
            hidden={ctype !== 0}
            rules={[{ required: ctype === 0 }]}
            label='发票上传'
            field='upload2'
            triggerPropName='fileList2'
            help='请上传文件附件'
          >
            <Upload
              // listType='picture-card'
              // multiple
              // autoUpload={false}
              name='file'
              accept={'image/*,.pdf,.zip'}
              action={baseURL + '/public/upload_file'}
              headers={
                {
                  'Authorization': 'Bearer ' + token,
                  'FileType': 1,
                }
              }
            />
          </Form.Item>
          <FormItem
            label='快递单号'
            hidden={ctype !== 1}
            field='exnum'
            help='请输入快递单号'
            rules={[{ required: ctype === 1 }]}
          >
            <Input placeholder='' />
          </FormItem>
        </Form>
      </Modal>
    </Watermark>
  </>;
}

export default Inbox;