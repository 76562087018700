// import { useRef } from 'react';
import { Table, Button, Space, Watermark, Form, Message, Modal, Input, Upload, AutoComplete } from '@arco-design/web-react';
import { useEffect, useState } from 'react';
import { axios, baseURL } from '../../utils/net';
const FormItem = Form.Item;
// import { IconSearch } from '@arco-design/web-react/icon';
// const data = [
//     {
//         id: '1',
//         title: 'Jane Doe',
//         desc: '32 Park Road, London',
//     },
// ];
function ContractManage() {
    const token = window.localStorage.getItem("token");
    const [visible, setVisible] = useState(false);
    // const [fileList,setFileList] = useState([]);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();
    const [pushVisible, setPushVisible] = useState(false);
    const [pushConfirmLoading, setPushConfirmLoading] = useState(false);
    const [push_form] = Form.useForm();
    const [initFlag, setInitFlag] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [pagination, setPagination] = useState({
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
    });
    const [searchData, setSearchData] = useState([]);
    const [data, setData] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    // const [actionId, setActionId] = useState(0);

    const loadData = ({ offset, limit }) => {
        // console.log(offset,limit);
        setDataLoading(true);
        axios.post("/manager/contract", {
            offset: offset === undefined ? pagination.current - 1 : offset,
            limit: limit === undefined ? pagination.pageSize : limit,
        }).then((res) => {
            if (res.data.code === 0) {
                setPagination({
                    ...pagination,
                    current: res.data.data.offset + 1,
                    total: res.data.data.count,
                });
                setData(res.data.data.payload === null ? [] : res.data.data.payload);
            } else {
                Message.error(res.data.msg);
            }
        }).catch((err) => {
            console.log(err);
            Message.error("获取数据失败");
        }).finally(() => {
            setDataLoading(false);
        });
    }

    useEffect(() => {
        // console.log(initFlag);
        if (initFlag) {
            return
        }
        axios.post("/manager/contract", {
            offset: 0,
            limit: 10,
        }).then((res) => {
            if (res.data.code === 0) {
                setPagination({
                    ...pagination,
                    current: res.data.data.offset + 1,
                    total: res.data.data.count,
                });
                setData(res.data.data.payload === null ? [] : res.data.data.payload);
            } else {
                Message.error(res.data.msg);
            }
        }).catch((err) => {
            console.log(err);
            Message.error("获取数据失败");
        }).finally(() => {
            setDataLoading(false);
            setInitFlag(true);
        });
    });

    function onChangeTable(paginationData) {
        // console.log(paginationData);
        const { current, pageSize } = paginationData;
        setPagination({
            ...pagination,
            current: current,
            pageSize: pageSize
        });
        // console.log(current,pageSize);
        loadData({ offset: current - 1, limit: pageSize });
        // setPagination({ ...pagination, current, pageSize });
        // loadData();
    }

    function DelContract(id) {
        if (id) {
            setConfirmLoading(true);
            axios.post('/manager/contract/del', { id: id }).then((res) => {
                console.log(res);
                if (res.data.code === 200) {
                    Message.success('删除成功');
                    loadData(pagination.current - 1, pagination.pageSize)
                } else {
                    Message.error(res.data.msg);
                }
            }).catch(() => {
                Message.error('删除失败');
            }).finally(() => {
                setConfirmLoading(false);
            });
        } else {
            Message.error('请选择要删除的合同');
        }
    }

    function onOk() {
        form.validate().then((res) => {
            console.log(res);
            if (res.upload.length === 0) {
                Message.error("请上传合同");
                return;
            }
            // 检查所有附件的上传状态
            for (var si = 0; si < res.upload.length; si++) {
                if (res.upload[si].status !== 'done') {
                    Message.error("请等待所有附件上传完成");
                    return;
                }
            }
            // 获取合同上传状态和targetLink
            var file = [];
            for (var i = 0; i < res.upload.length; i++) {
                file.push(res.upload[i].response.data.data.link);
            }
            console.log(file);
            setConfirmLoading(true);
            let val = form.getFieldsValue(['title', 'desc']);
            val = { ...val, file }
            axios.post('/manager/contract/change', val).then((res) => {
                if (res.data.code !== 0) {
                    Message.warning(res.data.msg);
                } else {
                    Message.success('添加合同成功 !');
                    setVisible(false);
                    form.clearFields(['title', 'desc', 'upload']);
                    loadData(pagination.current - 1, pagination.pageSize)
                    // 重新请求用户数据
                }
            }).catch(() => {
                Message.error('发生未知错误 !');
                // setConfirmLoading(false);
            }).finally(() => {
                setConfirmLoading(false);
            });
            setVisible(false);
            form.clearFields(['title', 'desc', 'upload']);
        }).catch(err => {
            console.log(err);
            Message.error('参数错误 !');
        }).finally(() => {
            setConfirmLoading(false);
        });
    }

    const handleSearch = (inputValue) => {
        if (inputValue === '') {
            return
        }
        // 搜索用户
        setSearchLoading(true);
        axios.post('/manager/search_user', { username: inputValue }).then((res) => {
            // console.log(res);
            if (res.data.code === 0) {
                setSearchData(res.data.data === null ? [] : res.data.data.length === 0 ? [] : res.data.data)
                // console.log(res.data.data);
            } else {
                setSearchData([])
            }
        }).catch(() => {
            // Message.error('删除失败');
        }).finally(() => {
            // setConfirmLoading(false);
            setSearchLoading(false);
        });
        // setSearchData(inputValue ? new Array(5).fill(null).map((_, index) => `${inputValue}_${index}`) : []);
        // console.log(inputValue);
    };


    function pushOnOk() {
        push_form.validate().then((res) => {
            console.log(res);
            // 根据username反查searchData
            let user = searchData.find(item => item.Username === res.user);
            console.log(user);
            // return;
            setConfirmLoading(true);
            let val = push_form.getFieldsValue(['id', 'title', 'desc']);
            let payload = {
                "title": val.title,
                "recv_id": user.ID,
                "cid": val.id,
                "msg": "新合同附件",
                "aid": 0,
                "need_file": false
            }
            axios.post('/manager/contract/send', payload).then((res) => {
                if (res.data.code !== 0) {
                    Message.warning(res.data.msg);
                } else {
                    Message.success('发送合同成功 !');
                    push_form.clearFields();
                }
            }).catch(() => {
                Message.error('发生未知错误 !');
                // setConfirmLoading(false);
            }).finally(() => {
                setPushConfirmLoading(false);
            });
        }).catch(err => {
            console.log(err);
            Message.error('参数错误 !');
        }).finally(() => {
            setPushVisible(false);
            setPushConfirmLoading(false);
            push_form.clearFields();
        });
    }


    const formItemLayout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 20,
        },
    };
    //   const inputRef = useRef(null);
    const columns = [
        {
            title: '合同Id',
            dataIndex: 'ID',
        },
        {
            title: '合同标题',
            dataIndex: 'Title',
        },
        {
            title: '合同附件',
            dataIndex: 'File',
            render: (text, _) => (
                <p>
                    {text.split(',').length} 个合同附件
                </p>
            ),
        },
        {
            title: '合同描述',
            dataIndex: 'Desc',
        },
        {
            title: '操作',
            //   dataIndex: 'email',
            dataIndex: 'op',
            render: (_, record) => (
                <Space size='large'>
                    <Button
                        onClick={() => {
                            push_form.setFields({
                                id: { value: record.ID },
                                title: { value: record.Title },
                                desc: { value: record.Desc }
                            })
                            setPushVisible(true);
                        }}
                        type='primary'
                        status='info'
                    >
                        下发
                    </Button>
                    <Button
                        onClick={() => {
                            DelContract(record.ID);
                        }}
                        type='primary'
                        status='danger'
                    >
                        删除
                    </Button>
                </Space>
            )
        },
    ];
    return (
        <>
            <Button
                style={{
                    marginBottom: 10,
                    marginTop: 10,
                    marginLeft: 10,
                    width: '200px'
                }}
                type='primary'
                onClick={() => { setVisible(true) }}
            >
                添加合同附件
            </Button>
            <Watermark content={"山东建安合同管理系统"}>
                <Table
                    loading={dataLoading}
                    columns={columns}
                    data={data}
                    onChange={onChangeTable}
                    pagination={pagination}
                />
            </Watermark>
            {/* 合同上传 */}
            <Modal
                title='添加合并附件'
                visible={visible}
                onOk={onOk}
                confirmLoading={confirmLoading}
                onCancel={() => setVisible(false)}
            >
                <Form
                    {...formItemLayout}
                    form={form}
                    labelCol={{
                        style: { flexBasis: 90 },
                    }}
                    wrapperCol={{
                        style: { flexBasis: 'calc(100% - 90px)' },
                    }}
                >
                    <FormItem label='合同名称' field='title' rules={[{ required: true }]}>
                        <Input placeholder='' />
                    </FormItem>
                    <FormItem label='合同描述' field='desc' rules={[{ required: true }]}>
                        <Input placeholder='' />
                    </FormItem>
                    <Form.Item
                        rules={[{ required: true }]}
                        label='合同文件'
                        field='upload'
                        triggerPropName='fileList'
                    // initialValue={[
                    //     {
                    //         uid: '-1',
                    //         url: '//p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/e278888093bef8910e829486fb45dd69.png~tplv-uwbnlip3yd-webp.webp',
                    //         name: '20200717',
                    //     },
                    // ]}
                    >
                        <Upload
                            // listType='picture-card'
                            // multiple
                            // autoUpload={false}
                            name='file'
                            accept={'image/*,.pdf,.zip'}
                            action={baseURL + '/public/upload_file'}
                            headers={
                                {
                                    'Authorization': 'Bearer ' + token,
                                    'FileType': 0,
                                }
                            }
                        // onChange={(info) => {
                        //     console.log(info);
                        // }}
                        // onPreview={(file) => {
                        //     Modal.info({
                        //         title: 'Preview',
                        //         content: (
                        //             <img
                        //                 src={file.url || URL.createObjectURL(file.originFile)}
                        //                 style={{
                        //                     maxWidth: '100%',
                        //                 }}
                        //             ></img>
                        //         ),
                        //     });
                        // }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            {/* 合同下发 */}
            <Modal
                title='合同下发'
                visible={pushVisible}
                onOk={pushOnOk}
                confirmLoading={pushConfirmLoading}
                onCancel={() => setPushVisible(false)}
            >
                <Form
                    {...formItemLayout}
                    form={push_form}
                    labelCol={{
                        style: { flexBasis: 90 },
                    }}
                    wrapperCol={{
                        style: { flexBasis: 'calc(100% - 90px)' },
                    }}
                >
                    <FormItem label='合同ID' field='id' hidden disabled rules={[{ required: true }]}>
                        <Input placeholder='' />
                    </FormItem>
                    <FormItem label='合同名称' field='title' disabled rules={[{ required: true }]}>
                        <Input placeholder='' />
                    </FormItem>
                    <FormItem label='合同描述' field='desc' disabled rules={[{ required: true }]}>
                        <Input placeholder='' />
                    </FormItem>
                    <FormItem label='接收用户' field='user' rules={[{ required: true }]}>
                        <AutoComplete
                            placeholder='Please Enter'
                            onSearch={handleSearch}
                            data={searchData.map(item => item.Username)}
                            loading={searchLoading}
                        // style={{ width: 154, marginRight: 20 }}
                        />
                    </FormItem>
                </Form>
            </Modal>
        </>
    );
}

export default ContractManage;