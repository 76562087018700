import { Form, Input, Button, Space, Message, Spin } from '@arco-design/web-react';
import "@arco-design/web-react/dist/css/arco.css";
import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { axios } from '../utils/net';
import { cryptPwd } from '../utils/crypto';
import GeeTest from 'react-geetest-v4';
const FormItem = Form.Item;
const Index = () => {
    // let geetestValue = null;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [geeLoading, setGeeLoading] = useState(true);
    const [geetestValue, setGeetestValue] = useState(null);
    const [disableRegister] = useState(true);
    const captchaRef = useRef(null);
    // const { captcha, geestate } = useGeeTest('f7a12b48b1821f328a8ce409c9707a73', {
    //     product: 'bind',
    //     containerId: 'srm',
    // });

    // captcha?.onReady(() => {
    //     setGeeLoading(false);
    // });

    // captcha?.onSuccess((res) => {
    //     if (captcha?.getValidate() && !proLogin) {
    //         proLogin = true;
    //         login()
    //     }
    // });


    // function preLogin() {
    //     if (captcha?.getValidate() === undefined) {
    //         captcha?.showCaptcha();
    //     }
    // }
    function login() {
        // geetestValue = captcha?.getValidate();
        form.validate({ validateOnly: true }).then(() => {
            if (geetestValue == null) {
                Message.warning('请先完成验证')
                return
            }
            // console.log(form.getFieldsValue(['username','password']));
            let val = form.getFieldsValue(['username', 'password']);
            val.password = cryptPwd(val.password);
            val = { ...val, ...geetestValue };
            // console.log(cryptPwd(val.password));
            setLoading(true);
            axios.post('/login', val).then(res => {
                if (res.data.code !== 0) {
                    Message.error(res.data.msg);
                    return
                }
                navigate("/user");
            }).catch(e => {
                console.log(e);
            }).finally(() => {
                setLoading(false);
                captchaRef?.current.reset();
            });
        }).catch(e => {
            // Message.error(e);
            // console.log(e.errors)
        });
    }
    return (
        // <Spin loading={geeLoading}>
        <div style={{ display: 'flex', alignItems: 'center', height: '100vh' }}>
            <div style={{ flex: 1, backgroundColor: '#3C6EE1', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                    {/* <img src="logo.png" alt="Logo" style={{ maxWidth: '200px' }} /> */}
                    <h2 style={{ color: 'white', fontSize: '30px' }}>山东建安合同管理系统</h2>
                </div>
            </div>
            <div style={{
                flex: 1,
                padding: '0 24px',
            }}>
                <h1>用户登录</h1>
                <Spin loading={geeLoading}>
                    <Form
                        style={{ maxWidth: 600 }}
                        autoComplete='off'
                        form={form}
                    >
                        <FormItem label='账户' field='username' rules={[{ required: true }]}>
                            <Input style={{ width: 260,height: 40 }} placeholder='用户账户或公司名称' />
                        </FormItem>
                        <FormItem label='密码' field='password' rules={[{ required: true }]}>
                            <Input.Password style={{ width: 260,height: 40 }} placeholder='登陆密码' />
                        </FormItem>
                        <FormItem wrapperCol={{ offset: 5 }}>
                            <GeeTest
                                captchaId={'f7a12b48b1821f328a8ce409c9707a73'}
                                containerId={'srm'} // Optional
                                product={'float'}
                                onSuccess={(result) => setGeetestValue(result)}
                                onReady={() => setGeeLoading(false)}
                                ref={captchaRef}
                            />
                        </FormItem>
                        {/* <FormItem wrapperCol={{ offset: 5 }}>
                        <Checkbox>I have read the manual</Checkbox>
                    </FormItem> */}
                        <FormItem wrapperCol={{ offset: 5 }}>
                            <Space>
                                <Button type='primary' htmlType='submit' onClick={login} loading={loading}>
                                    登录
                                </Button>
                                <Link to="/register">
                                    <Button type='outline' disabled={disableRegister}>
                                        注册
                                    </Button>
                                </Link>
                            </Space>
                        </FormItem>
                    </Form>
                </Spin>
            </div>
        </div>
        // </Spin>
    );
};

export default Index;