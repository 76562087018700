//每种身份对应的菜单

import ContractManage from "../pages/admin/contractManage";
import ItoManage from "../pages/admin/itoManage";
import ManagerUser from "../pages/admin/managerUser";
// import ReceiptsManage from "../pages/admin/receiptsManage";
import SendboxManage from "../pages/admin/sendboxManage";
import ChangePassword from "../pages/public/change_password";
import Inbox from "../pages/user/inbox";

// menu_type 1:一级菜单  2:二级菜单
const menu = [
    {
        "title": "管理员用户",
        "key": "manager/users",
        "menu_type": 1,
        "component": <ManagerUser />,
        "role": [1],
    },
    {
        "title": "企业/公司管理",
        "key": "manager/ito",
        "menu_type": 1,
        "component": <ItoManage />,
        "role": [1, 2],
    },
    {
        "title": "合同管理",
        "key": "contractMenu",
        "menu_type": 2,
        "role": [1, 2],
        "submenu": [
            {
                "title": "合同下发管理",
                "key": "manager/contract",
                "menu_type": 1,
                "component": <ContractManage />,
                "role": [1, 2],
            },
            {
                "title": "合同回执管理",
                "key": "manager/sendbox",
                "menu_type": 1,
                "component": <SendboxManage />,
                "role": [1, 2],
            },
            // {
            //     "title": "回传合同查阅",
            //     "key": "manager/receipts",
            //     "menu_type": 1,
            //     "component": <ReceiptsManage />,
            //     "role": [1, 2],
            // },
        ],
    },
    {
        "title": "文件列表",
        "key": "inbox",
        "menu_type": 1,
        "component": <Inbox />,
        "role": [0],
    },
    {
        "title": "修改密码",
        "key": "change_password",
        "menu_type": 1,
        "component": <ChangePassword />,
        "role": [0,1,2],
    },
]

const filterMenuByRole = (identity, tmenu = menu) => {
    // 遍历菜单项
    const filteredMenu = menu.filter((item) => {
        // 如果菜单项没有 role 属性，则保留该项
        if (!item.role) {
            return true;
        }
        // 检查菜单项的 role 值是否包含给定的 identity
        return item.role.includes(identity);
    });
    filteredMenu.forEach(item => {
        if (item.submenu) {
            item.submenu = item.submenu.filter((item) => {
                console.log(item);
                // 检查菜单项的 role 值是否包含给定的 identity
                return item.role.includes(identity);
            });
        }
    });
    return filteredMenu;
};

const checkPath = (path) => {
    // let result = '';
    // 遍历菜单项
    // let c = menu.find((item) => {
    //     console.log(path,"---",item.key,"---",path.endsWith(item.key));
    //     if (path.endsWith(item.key)) {
    //         return true
    //     }
    // });
    let c = null;
    menu.forEach(item => {
        if (path.endsWith(item.key)) {
            c = item.key
            return
        }
        if (item.submenu) {
            item.submenu.forEach(item => {
                if (path.endsWith(item.key)) {
                    c = item.key
                    return
                }
            })
        }
    })
    console.log(c);
    return c
}

export { filterMenuByRole,checkPath }