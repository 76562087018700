import './App.css';
import { Route, Routes } from "react-router-dom"
import Index from './pages';
import Register from './pages/register';
import NotFound from './pages/404';
import MenuLayout from './pages/menu/menu';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/user/*" element={<MenuLayout />} />
      <Route path="/login" element={<Index />} />
      <Route path="/register" element={<Register />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
