import { Component } from "react";
import { Form, Input, Checkbox, Button, Space } from '@arco-design/web-react';
import "@arco-design/web-react/dist/css/arco.css";
import React from 'react';
import { Link } from 'react-router-dom';
const FormItem = Form.Item;

class Register extends Component {
    state = {}
    render() {

        return (
            <div style={{ display: 'flex', alignItems: 'center', height: '100vh' }}>
                <div style={{ flex: 1, backgroundColor: '#3C6EE1', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ textAlign: 'center' }}>
                        {/* <img src="logo.png" alt="Logo" style={{ maxWidth: '200px' }} /> */}
                        <h2 style={{ color: 'white', fontSize: '30px' }}>山东建安合同管理系统</h2>
                    </div>
                </div>
                <div style={{
                    flex: 1,
                    padding: '0 24px',
                }}>
                    <h1>用户注册</h1>
                    <Form
                        style={{ maxWidth: 600 }}
                        autoComplete='off'
                    >
                        <FormItem label='公司昵称' field='username' tooltip={<div>Username is required </div>} rules={[{ required: true }]}>
                            <Input style={{ width: 270 }} placeholder='用户账户或公司名称' />
                        </FormItem>
                        <FormItem label='密码' field='password' tooltip={<div>Password is required </div>} rules={[{ required: true }]}>
                            <Input.Password style={{ width: 270 }} placeholder='登陆密码' />
                        </FormItem>
                        <FormItem label='重复密码' field='repassword' tooltip={<div>Password is required </div>} rules={[{ required: true }]}>
                            <Input.Password style={{ width: 270 }} placeholder='登陆密码' />
                        </FormItem>
                        <FormItem wrapperCol={{ offset: 5 }}>
                            <Checkbox>I have read the manual</Checkbox>
                        </FormItem>
                        <FormItem wrapperCol={{ offset: 5 }}>
                            <Space>
                                <Button type='primary' htmlType='submit'>
                                    注册
                                </Button>
                                <Link to="/login">
                                    <Button type='outline'>
                                        登录
                                    </Button>
                                </Link>
                            </Space>
                        </FormItem>
                    </Form>
                </div>
            </div>
        );

    }
}

export default Register;