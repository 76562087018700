import { Message } from '@arco-design/web-react';
import axios_src from 'axios';
// import { message } from 'antd';
let cancelFunc = () => { };
let baseURL = "https://srm.shandongjianan.com/api/v1";
// let baseURL = "http://127.0.0.1:8080/api/v1";
// let baseURL = "http://127.0.0.1:8099";

let axios = axios_src.create({
    timeout: 100000,
    baseURL: baseURL + "/",
    headers: {
        'content-type': 'application/json',
    },
    // cancelToken: axios_src.CancelToken(function (c) {
    //     // c 用于取消当前请求的函数
    //     cancelFunc = c
    // }),
})

axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断是否存在token
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const token = window.localStorage.getItem("token");
        token && (config.headers.Authorization = "Bearer " + token);
        return config;
    },
    error => {
        return Promise.error(error);
    }
)

function router(path) {
    window.localStorage.removeItem("token");
    window.location.href = path;
    // Navigate(path);
}

function getUser() {
    const token = window.localStorage.getItem("token");
    const payloadBase64 = token.split('.')[1];
    const payload = atob(payloadBase64);
    const user = JSON.parse(payload);
    let data = {
        uid: user.uid,
        identify: user.identify,
        status: user.status,
    }
    return data;
}

function logout() {
    window.localStorage.removeItem("token");
    window.location.href = '/';
}

axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status === 200) {
            if (response.data.token) {
                let info = response.data.token.split(".")[1]
                let user = JSON.parse(window.atob(info));
                if (user.status !== 1) {
                    Message.warning({
                        content: '账号已被禁用，请联系管理员',
                        key: 'login',
                        duration: 1500,
                    });
                    // message.warning({
                    //     content: "您的账号无权限登陆！",
                    // });
                    return Promise.reject("您的账号无登陆权限！");
                }
                window.localStorage.setItem("token", response.data.token);
            }
            return Promise.resolve(response);
        } else {
            Message.error({
                content: "服务器错误！",
                duration: 1500
            });
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    error => {
        if (error.response) {
            switch (error.response.status) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
                    let content = error.response.data.msg;
                    // let code = error.response.data.code;
                    Message.error({
                        duration: 1500,
                        content: content,
                        onClose: () => {
                            // 跳转到登录页
                            router("/")
                            // window.location.href = "/login";
                        },
                    });
                    break;

                // 404请求不存在
                case 404:
                    Message.error("接口未定义！");
                    break;
                case 500:
                    Message.error("服务器错误！");
                    break;
                // 其他错误，直接抛出错误提示
                default:
                    console.log(error.response.data);
            }
        } else if (error.message.includes('timeout')) {
            Message.warning('请求超时！');
            //console.log("timeout")
        }
        return Promise.reject(error.response);
    },
)

export { axios, cancelFunc, baseURL, logout, getUser };